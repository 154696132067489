import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{ maxWidth: `2000px`, marginBottom: `1.45rem`, backgroundColor: 'black'}}>
      <Image />
    </div>
  </Layout>
)

export default IndexPage
